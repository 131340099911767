.rmc-tabs {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.rmc-tabs * {
  box-sizing: border-box;
}
.rmc-tabs-content-wrap {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
      flex: 1;
  width: 100%;
  height: 100%;
}
.rmc-tabs-content-wrap-animated {
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), top 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: transform, left, top;
}
.rmc-tabs-pane-wrap {
  width: 100%;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  overflow-y: auto;
}
.rmc-tabs-tab-bar-wrap {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.rmc-tabs-horizontal .rmc-tabs-pane-wrap-active {
  height: auto;
}
.rmc-tabs-horizontal .rmc-tabs-pane-wrap-inactive {
  height: 0;
  overflow: visible;
}
.rmc-tabs-vertical .rmc-tabs-content-wrap {
  -ms-flex-direction: column;
      flex-direction: column;
}
.rmc-tabs-vertical .rmc-tabs-tab-bar-wrap {
  height: 100%;
}
.rmc-tabs-vertical .rmc-tabs-pane-wrap {
  height: 100%;
}
.rmc-tabs-vertical .rmc-tabs-pane-wrap-active {
  overflow: auto;
}
.rmc-tabs-vertical .rmc-tabs-pane-wrap-inactive {
  overflow: hidden;
}
.rmc-tabs-top,
.rmc-tabs-bottom {
  -ms-flex-direction: column;
      flex-direction: column;
}
.rmc-tabs-left,
.rmc-tabs-right {
  -ms-flex-direction: row;
      flex-direction: row;
}
.rmc-tabs-tab-bar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-direction: row;
      flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 1;
}
.rmc-tabs-tab-bar-tab {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  font-size: 14px;
  line-height: 14px;
}
.rmc-tabs-tab-bar-tab-active {
  color: #108ee9;
}
.rmc-tabs-tab-bar-underline {
  position: absolute;
  border: 1px #108ee9 solid;
}
.rmc-tabs-tab-bar-animated .rmc-tabs-tab-bar-content {
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: transform;
}
.rmc-tabs-tab-bar-animated .rmc-tabs-tab-bar-underline {
  transition: top 0.3s cubic-bezier(0.35, 0, 0.25, 1), left 0.3s cubic-bezier(0.35, 0, 0.25, 1), color 0.3s cubic-bezier(0.35, 0, 0.25, 1), width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  will-change: top, left, width, color;
}
.rmc-tabs-tab-bar-top,
.rmc-tabs-tab-bar-bottom {
  -ms-flex-direction: row;
      flex-direction: row;
}
.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-content,
.rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-content {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-direction: row;
      flex-direction: row;
}
.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-prevpage,
.rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-prevpage {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: block;
  width: 59px;
  height: 100%;
  content: ' ';
  z-index: 1;
  left: 0;
  background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}
.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-nextpage,
.rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-nextpage {
  pointer-events: none;
  position: absolute;
  top: 0;
  display: block;
  width: 59px;
  height: 100%;
  content: ' ';
  z-index: 1;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
}
.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-tab,
.rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-tab {
  padding: 8px 0;
}
.rmc-tabs-tab-bar-top .rmc-tabs-tab-bar-underline,
.rmc-tabs-tab-bar-bottom .rmc-tabs-tab-bar-underline {
  bottom: 0;
}
.rmc-tabs-tab-bar-top {
  border-bottom: 1px #eee solid;
}
.rmc-tabs-tab-bar-bottom {
  border-top: 1px #eee solid;
}
.rmc-tabs-tab-bar-left,
.rmc-tabs-tab-bar-right {
  -ms-flex-direction: column;
      flex-direction: column;
}
.rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-content,
.rmc-tabs-tab-bar-right .rmc-tabs-tab-bar-content {
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -ms-flex-direction: column;
      flex-direction: column;
}
.rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-tab,
.rmc-tabs-tab-bar-right .rmc-tabs-tab-bar-tab {
  padding: 0 8px;
}
.rmc-tabs-tab-bar-left {
  border-right: 1px #eee solid;
}
.rmc-tabs-tab-bar-left .rmc-tabs-tab-bar-underline {
  right: 0;
}
.rmc-tabs-tab-bar-right {
  border-left: 1px #eee solid;
}
.rmc-tabs-tab-bar-right .rmc-tabs-tab-bar-underline {
  left: 0;
}
